<template>
    <div>
        Placeholder for Reconciliation Home
    </div>
</template>

<script>
export default {
  name: 'reconciliation-home'
}
</script>
